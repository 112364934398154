(function () {
  app.controller("AdminSeatingSectionLayoutMapCtrl", [
    "$scope",
    "$http",
    "$timeout",
    "$window",
    "$controller",
    function ($scope, $http, $timeout, $window, $controller) {
      $controller("BookingsCtrl", { $scope: $scope });

      $scope.seatingMapUpdateInterval = 1000;

      $scope.updateMap = function (unavailableSeats) {
        $scope.layout = JSON.parse(JSON.stringify($scope.empty_layout));
        unavailableSeats.map(function (seat) {
          seat.trigger = "hover";
          seat.class = "cursor-pointer ";

          if (seat.available) {
            seat.class += "available ";
          } else {
            seat.class += "unavailable ";

            if (seat.reserved) {
              seat.class += "reserved";
            } else if (seat.held_by_customer) {
              seat.class += "held-by-customer";
            }

            if (seat.held_by_different_admin) {
              seat.class += "held-by-admin";
            }

            if (seat.held_by_you) {
              seat.class += "held-by-you";
            }
          }
          $scope.layout[seat.row][seat.column] = seat;
        });
      };

      $scope.updateAvailableSeats = function (after) {
        $http
          .get(
            "/admin/seating_sections/" + $scope.active_ticket_section + "/map",
            {}
          )
          .then(function (res) {
            $scope.updateMap(res.data.data);
            $scope.report = res.data.report;
            if (after) after();
          })
          .catch(function (res) {
            $scope.data = "Server error";
            if (after) after();
          });
      };

      $scope.onReady = function () {
        $scope.updateSeatingMapLoop();
        $scope.initializeOwl();
        $scope.initializeSelect2();
      };

      $scope.initializeSelect2 = function () {
        let select = $("#seating_section");
        if (select) {
          select.select2();
          select.val($scope.active_ticket_section).trigger("change");
        }
        $("#seating_section").on("select2:select", function (e) {
          let url = $("#seating_section")
            .select2()
            .find(":selected")
            .data("url");
          window.location.replace(url);
        });
      };

      $("a")
        .not(".btn")
        .on("click", function (e) {
          e.preventDefault();
          $scope.reloadRoute($(this).attr("href"));
        });

      $scope.reloadRoute = function (url) {
        window.location.replace(url);
      };

      $scope.shouldUpdateSeatingMap = function () {
        return true;
      };

      $scope.shouldDisplaySeatingMap = function () {
        return true;
      };

      $scope.holdSeat = function (seat, $event) {
        $event.stopPropagation();
        $event.preventDefault();
        $.ajax({
          url: "/admin/seating_section_layouts/" + seat.id + "/hold",
          type: "PUT",
          success: function (rs) {
            $timeout(function () {
              if (rs.success) {
                $scope.updateAvailableSeats();
              } else {
                console.error(rs.message);
              }
            });
          },
        });
      };

      $scope.releaseHoldSeat = function (seat) {
        $.ajax({
          url: "/admin/seating_section_layouts/" + seat.id + "/release_hold",
          type: "PUT",
          success: function (rs) {
            $timeout(function () {
              if (rs.success) {
                $scope.updateAvailableSeats();
              } else {
                $scope.updateAvailableSeats();
                console.error(rs.message);
              }
            });
          },
        });
      };
    },
  ]);
}.call(this));
